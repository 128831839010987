import {
  Badge,
  Button,
  Flex,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { FaEllipsisV } from "react-icons/fa";
import Swal from "sweetalert2";
import { API, graphqlOperation } from "aws-amplify";
import {
  deleteAuditedAccounts,
  updateAuditedAccounts,
} from "graphql/mutations"; // Asegúrate de que esta mutación esté definida

function TablesTableRowAuditedAccounts(props) {
  const { id, name, url, auditedAccounts, getAllAuditedAccounts, isLast } =
    props;

  const borderColor = useColorModeValue("gray.200", "gray.600");

  async function deleteMentalidadFn() {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Esta operación no se puede revertir",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Borrar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const mentalidadDetails = { id: id };
        try {
          await API.graphql(
            graphqlOperation(deleteAuditedAccounts, {
              input: mentalidadDetails,
            }),
          );
          Swal.fire(
            "Eliminada!",
            "La cuenta auditada ha sido eliminada.",
            "success",
          );
          getAllAuditedAccounts();
        } catch (error) {
          Swal.fire(
            "Error",
            "La cuenta auditada no ha sido eliminada.",
            "error",
          );
          console.log(error);
        }
      }
    });
  }

  async function updateMentalidadFn() {
    const { value: formValues } = await Swal.fire({
      title: "Editar Cuenta Auditada",
      html: `<input id="swal-input1" class="swal2-input" placeholder="Nombre" value="${name}">
      <input id="swal-input2" class="swal2-input" placeholder="URL" value="${url}">`,
      focusConfirm: false,
      preConfirm: () => {
        return [
          document.getElementById("swal-input1").value,
          document.getElementById("swal-input2").value,
        ];
      },
    });

    if (formValues) {
      const [newName, newUrl] = formValues;

      const auditedAccountsDetails = {
        id: id,
        name: newName,
        url: newUrl,
      };

      try {
        await API.graphql(
          graphqlOperation(updateAuditedAccounts, {
            input: auditedAccountsDetails,
          }),
        );
        Swal.fire(
          "Actualizada!",
          "La cuenta auditada ha sido actualizada.",
          "success",
        );
        getAllAuditedAccounts();
      } catch (error) {
        Swal.fire(
          "Error",
          "La cuenta auditada no ha sido actualizada.",
          "error",
        );
        console.log(error);
      }
    }
  }

  return (
    <Tr>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text fontSize="md" fontWeight="bold">
          {id}
        </Text>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text fontSize="md">{name}</Text>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text fontSize="md">{url}</Text>
      </Td>

      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Menu>
          <MenuButton>
            <Icon as={FaEllipsisV} color="gray.400" cursor="pointer" />
          </MenuButton>
          <MenuList>
            <MenuItem onClick={deleteMentalidadFn}>Eliminar</MenuItem>
            <MenuItem onClick={updateMentalidadFn}>Editar</MenuItem>
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  );
}

export default TablesTableRowAuditedAccounts;
