// @ts-check
import { Box, LinearProgress, Typography } from "@material-ui/core";

export function LinearProgressWithLabel({ progress, total }) {
  const percent = (progress / total) * 100;
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" value={percent} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          percent,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
