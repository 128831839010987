import {
  useTheme,
  Button,
  View,
  Heading,
  Text,
  Image,
  ThemeProvider,
  Authenticator,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

export function AuthStyle() {
  const { tokens } = useTheme();

  const components = {
    Header() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Image
            alt="Logo de BMaker"
            src={require("./assets/Colorlogonobackground.png")}
            style={{ width: "16rem", height: "auto" }}
          />
        </View>
      );
    },

    Footer() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Text color={tokens.colors.neutral[80]}>
            &copy; BMaker Pro - Todos los derechos reservados
          </Text>
        </View>
      );
    },

    SignIn: {
      Header() {
        const { tokens } = useTheme();

        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Inicia sesión en tu cuenta
          </Heading>
        );
      },
      Footer() {
        const { toResetPassword } = useAuthenticator();

        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toResetPassword}
              size="small"
              variation="link"
            >
              Restablecer contraseña
            </Button>
          </View>
        );
      },
    },

    SignUp: {
      Header() {
        const { tokens } = useTheme();

        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Crea una nueva cuenta
          </Heading>
        );
      },
      Footer() {
        const { toSignIn } = useAuthenticator();

        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toSignIn}
              size="small"
              variation="link"
            >
              Volver a Iniciar Sesión
            </Button>
          </View>
        );
      },
    },
    ConfirmSignUp: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Ingresa la Información:
          </Heading>
        );
      },
    },
    SetupTOTP: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Ingresa la Información:
          </Heading>
        );
      },
    },
    ConfirmSignIn: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Ingresa la Información:
          </Heading>
        );
      },
    },
    ResetPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Ingresa la Información:
          </Heading>
        );
      },
      Footer() {
        return <Text>No te preocupes, te respaldamos!</Text>;
      },
    },
    ConfirmResetPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Ingresa la Información:
          </Heading>
        );
      },
    },
  };

  const formFields = {
    signIn: {
      username: {
        placeholder: "Ingresa tu nombre de usuario",
      },
    },
    signUp: {
      password: {
        label: "Contraseña:",
        placeholder: "Ingresa tu Contraseña:",
        isRequired: false,
        order: 2,
      },
      confirm_password: {
        label: "Confirmar Contraseña:",
        order: 1,
      },
    },
    forceNewPassword: {
      password: {
        placeholder: "Ingresa tu Contraseña:",
      },
    },
    resetPassword: {
      username: {
        placeholder: "Ingresa tu correo electrónico:",
      },
    },
    confirmResetPassword: {
      confirmation_code: {
        placeholder: "Ingresa tu Código de Confirmación:",
        label: "Nuevo Etiqueta",
        isRequired: false,
      },
      confirm_password: {
        placeholder: "Ingresa tu Contraseña Por Favor:",
      },
    },
    setupTOTP: {
      QR: {
        totpIssuer: "emisor de prueba",
        totpUsername: "usuario_prueba_amplify_qr",
      },
      confirmation_code: {
        label: "Nuevo Etiqueta",
        placeholder: "Ingresa tu Código de Confirmación:",
        isRequired: false,
      },
    },
    confirmSignIn: {
      confirmation_code: {
        label: "Nuevo Etiqueta",
        placeholder: "Ingresa tu Código de Confirmación:",
        isRequired: false,
      },
    },
  };
  const theme = {
    name: "Auth Example Theme",
    tokens: {
      colors: {
        brand: {
          primary: {
            10: tokens.colors.blue["200"],
            80: "#5096cb",
            90: tokens.colors.blue["20"],
            100: tokens.colors.blue["10"],
          },
        },
      },
      components: {
        tabs: {
          item: {
            _hover: {
              color: {
                value: tokens.colors.blue["80"].value,
              },
            },
          },
        },
      },
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <Authenticator
        formFields={formFields}
        components={components}
      ></Authenticator>
    </ThemeProvider>
  );
}
