// @ts-check
import {
  Badge,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaEllipsisV } from "react-icons/fa";
import Swal from "sweetalert2";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { deleteEnvivos, updateEnvivos } from "graphql/mutations";
import {
  SchedulerClient,
  DeleteScheduleCommand,
  UpdateScheduleCommand,
} from "@aws-sdk/client-scheduler";
import { rescheduleNotification } from "notifications/NotificatorScheduler";
import { validateLive } from "types/Live/ValidateLive";

/**
 * @typedef {Object} TablesTableRowEnvivosProps
 * @property {string} id - id of the live
 * @property {Envivo} live - live to display
 * @property {function} setUpdate - function to update the lives
 * @property {number} key - key of the live
 * @property {boolean} isLast - if it's the last live to list
 */

/**
 * Table row component for Envivos
 * @param {TablesTableRowEnvivosProps} props - input props
 * @returns {React.ReactElement}
 */
function TablesTableRowEnvivos({ id, live, setUpdate, key, isLast }) {
  const borderColor = useColorModeValue("gray.200", "gray.600");
  let client;
  Auth.currentCredentials().then((credentials) => {
    client = new SchedulerClient({
      region: "us-east-1",
      credentials,
    });
  });

  async function deleteEnvivoFn() {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Esta operación no se puede revertir",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Borrar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          if (new Date(`${live.envivoDate}T${live.envivoTime}`) > new Date()) {
            await client.send(
              new DeleteScheduleCommand({
                Name: live.scheduleID,
                GroupName: process.env.REACT_APP_SCHEDULER_GROUP_NAME,
              }),
            );
          }
          await API.graphql(graphqlOperation(deleteEnvivos, { input: { id } }));
          Swal.fire("Eliminado!", "El envivo ha sido eliminado.", "success");
          setUpdate(true);
        } catch (error) {
          Swal.fire("Error", "El envivo no ha sido eliminado.", "error");
          console.error(error);
        }
      }
    });
  }

  async function updateEnvivoFn() {
    const { value: formValues } = await Swal.fire({
      title: "Editar Envivo",
      html:
        `<label for="swal-input1">Título</label>` +
        `<input id="swal-input1" class="swal2-input" placeholder="Título" value="${live.title}">` +
        `<label for="swal-input2">Subtítulo</label>` +
        `<input id="swal-input2" class="swal2-input" placeholder="Subtítulo" value="${live.subtitle}">` +
        `<label for="swal-input3">Plataforma</label>` +
        `<input id="swal-input3" class="swal2-input" placeholder="Plataforma" value="${live.platform}">` +
        `<label for="swal-input4">URL</label>` +
        `<input id="swal-input4" class="swal2-input" placeholder="URL" value="${live.url}">` +
        `<label for="swal-input5">Visibilidad</label>` +
        `<input id="swal-input5" class="swal2-input" placeholder="Visibilidad" value="${live.visibility}">` +
        `<label for="swal-input7">Disponible Hasta</label>` +
        `<input id="swal-input7" class="swal2-input" type="date" placeholder="Disponible Hasta" value="${live.untilDate}">` +
        `<label for="swal-input8">Fecha del Envivo</label>` +
        `<input id="swal-input8" class="swal2-input" type="date" placeholder="Fecha del Envivo" value="${live.envivoDate}">` +
        `<label for="swal-input9">Hora del Envivo</label>` +
        `<input id="swal-input9" class="swal2-input" type="time" placeholder="Hora del Envivo" value="${live.envivoTime}">`,
      focusConfirm: false,
      preConfirm: () => {
        return [
          document.getElementById("swal-input1").value,
          document.getElementById("swal-input2").value,
          document.getElementById("swal-input3").value,
          document.getElementById("swal-input4").value,
          document.getElementById("swal-input5").value,
          document.getElementById("swal-input7").value,
          document.getElementById("swal-input8").value,
          document.getElementById("swal-input9").value,
        ];
      },
    });

    if (formValues) {
      const [
        title,
        subtitle,
        platform,
        url,
        visibility,
        untilDate,
        envivoDate,
        envivoTime,
      ] = formValues;

      const errors = validateLive({
        title,
        subtitle,
        platform,
        url,
        visibility,
        untilDate,
        envivoDate,
        envivoTime,
        coverImage: live.coverImage,
        description: live.description,
      });

      if (Object.keys(errors).length > 0) {
        Swal.fire(
          "El envivo no ha sido actualizado.",
          "Revisa los siguientes errores:</br>" +
            Object.keys(errors).map(
              (key) => `<b>${key}:</b> ${errors[key]}</br>`,
            ),
          "error",
        );
        return;
      }

      try {
        if (new Date(`${envivoDate}T${envivoTime}`) > new Date()) {
          await rescheduleNotification(
            live.scheduleID,
            title,
            new Date(`${envivoDate}T${envivoTime}`),
          );
        }
        await API.graphql(
          graphqlOperation(updateEnvivos, {
            input: {
              id,
              title,
              subtitle,
              platform,
              url,
              visibility,
              untilDate,
              envivoDate,
              envivoTime,
            },
          }),
        );
        Swal.fire("Actualizado!", "El envivo ha sido actualizado.", "success");
        setUpdate(true);
      } catch (error) {
        Swal.fire("Error", "El envivo no ha sido actualizado.", "error");
        console.log(error);
      }
    }
  }

  return (
    <Tr key={key}>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Image
          src={live.coverImage}
          boxSize="150px"
          objectFit="cover"
          mx="auto"
          cursor="pointer"
          onClick={() => {
            Swal.fire({
              imageUrl: live.coverImage,
              imageAlt: "Imagen del envivo",
              showCloseButton: true,
              showConfirmButton: false,
            });
          }}
        />

        <Text fontSize="md" fontWeight="bold">
          {live.title}
        </Text>
        <Text fontSize="sm" color="gray.400">
          {live.subtitle}
        </Text>
      </Td>
      {/* <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text fontSize="md">{live.timestamp}</Text>
      </Td> */}
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Badge colorScheme="blue">{live.platform}</Badge>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text fontSize="md">{live.url}</Text>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text fontSize="md">{live.visibility}</Text>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text fontSize="md">{live.publishedDate}</Text>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text fontSize="md">{live.untilDate}</Text>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text fontSize="md">{live.envivoDate}</Text>
        <Text fontSize="sm" color="gray.400">
          {live.envivoTime}
        </Text>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Menu>
          <MenuButton>
            <Icon as={FaEllipsisV} color="gray.400" cursor="pointer" />
          </MenuButton>
          <MenuList>
            <MenuItem onClick={deleteEnvivoFn}>Eliminar</MenuItem>
            <MenuItem onClick={updateEnvivoFn}>Editar</MenuItem>
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  );
}

export default TablesTableRowEnvivos;
