// @ts-check
import { Flex } from "@chakra-ui/react";
import { TutorialsCreateForm } from "./TutorialsCreateForm";
import { TutorialsTable } from "./TutorialsTable";
import { listTutorials } from "graphql/queries";
import useGraphqlOperation from "hooks/useGraphqlOperation";
import { Auth } from "aws-amplify";
import { S3Client } from "@aws-sdk/client-s3";
import { useEffect, useState } from "react";

export function TutorialsPage() {
  const { data, updateData, loading } = useGraphqlOperation(listTutorials);
  const [s3Client, setS3Client] = useState(null);

  useEffect(() => {
    Auth.currentCredentials().then((credentials) => {
      setS3Client(new S3Client({ region: "us-east-1", credentials }));
    });
  }, []);

  return (
    <Flex
      direction="column"
      pt={{ base: "120px", md: "75px" }}
      style={{ justifyContent: "center", alignItems: "center" }}
    >
      <TutorialsCreateForm
        updateData={updateData}
        loading={loading}
        s3Client={s3Client}
      />
      <TutorialsTable data={data} updateData={updateData} s3Client={s3Client} />
    </Flex>
  );
}
