import React, { useState, useEffect, useRef, Suspense } from "react";
import { API, graphqlOperation } from "aws-amplify";
// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Select,
  Table,
  Tbody,
  Text,
  Input,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Card,
} from "@chakra-ui/react";
// Custom components
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { HiOutlineRefresh } from "react-icons/hi";
import { RiSendPlaneFill } from "react-icons/ri";
import { AiOutlineClear } from "react-icons/ai";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { Formik, ErrorMessage } from "formik";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { listNoticias } from "graphql/queries";
import { createNoticia } from "graphql/mutations";
import { Loader } from "@aws-amplify/ui-react";
import { Img } from "react-image";
import TablesTableRowNews from "components/Tables/TablesTableRowNews";
import { notificateNews } from "notifications/Notificator";

function TablesNews() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const formRef = useRef();

  const [news, setNews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getAllNews = async () => {
    setIsLoading(true);
    const result = await API.graphql(graphqlOperation(listNoticias))
      .then((data) => {
        setNews(
          data?.data?.listNoticias?.items?.filter((item) => !item._deleted) ||
            [],
        );
        setIsLoading(false);
        console.log(data);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getAllNews();
  }, []);

  return (
    <Flex
      direction="column"
      pt={{ base: "120px", md: "75px" }}
      style={{ justifyContent: "center", alignItems: "center" }}
    >
      <Flex
        direction={{ lg: "row", xl: "row", sm: "column", md: "column" }}
        display="flex"
      >
        <Flex style={{ justifyContent: "center", alignItems: "center" }}>
          <Card
            overflowX={{ sm: "scroll", xl: "hidden" }}
            style={{ width: "40rem", padding: "1rem" }}
            pb="0px"
          >
            <CardHeader p="0px 0px 0px 0px">
              <Flex direction="column" w={{ sm: "100%" }}>
                <Text
                  fontSize="xl"
                  color={textColor}
                  fontWeight="bold"
                  pb=".5rem"
                >
                  Enviar Noticias Generales
                </Text>
              </Flex>
            </CardHeader>
            <CardBody>
              <Table variant="simple" color={textColor}>
                <Thead></Thead>
                <Tbody>
                  <Flex
                    style={{
                      width: "100%",
                      height: "auto",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Formik
                      id="formik"
                      innerRef={formRef}
                      initialValues={{
                        title: "",
                        subtitle: "",
                        type: "",
                        visibility: "",
                        url: "",
                        publishedDate: "",
                        portada: "",
                        author: "",
                      }}
                      validationSchema={Yup.object({
                        title: Yup.string().required("Requerido"),
                        subtitle: Yup.string().required("Requerido"),
                        type: Yup.string().required("Requerido"),
                        visibility: Yup.string().required("Requerido"),
                        publishedDate: Yup.date().required("Requerido"),
                        author: Yup.string().required("Requerido"),
                        url: Yup.string().required("Requerido"),
                        portada: Yup.string().required("Requerido"),
                      })}
                      onSubmit={async (values, { resetForm }) => {
                        const timestamp = new Date().toLocaleTimeString(
                          "es-CO",
                          {
                            hour12: false,
                          },
                        );

                        let payload = {
                          title: values.title,
                          subtitle: values.subtitle,
                          publishedDate: values.publishedDate.toString(),
                          type: values.type,
                          timestamp: timestamp.toString(),
                          url: values.url,
                          optionalImage: values.portada,
                          coverImage: values.portada,
                          visibility: values.visibility,
                          author: values.author,
                        };

                        try {
                          await API.graphql(
                            graphqlOperation(createNoticia, {
                              input: payload,
                            }),
                          );
                          notificateNews(payload);
                          Swal.fire({
                            title: "Noticia General Enviada",
                            text: "Por favor revisa la app",
                            icon: "success",
                            willClose: () => {
                              resetForm();
                              getAllNews();
                            },
                          });
                        } catch (error) {
                          console.log("Error", error);
                          Swal.fire(
                            "Revisa e intenta de nuevo",
                            error.message,
                            "error",
                          );
                        }
                      }}
                    >
                      {({
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        resetForm,
                        values,
                      }) => (
                        <>
                          <form onSubmit={handleSubmit}>
                            <FormControl>
                              <>
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="bold"
                                >
                                  Titulo
                                </FormLabel>
                                <Input
                                  variant="auth"
                                  fontSize="sm"
                                  ms="4px"
                                  type="text"
                                  placeholder="Titulo de Noticia"
                                  size="lg"
                                  id="form_title"
                                  name="title"
                                  onChange={handleChange}
                                  value={values.title}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  <ErrorMessage name="title" />
                                </div>
                              </>
                              <>
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="bold"
                                >
                                  Contenido
                                </FormLabel>
                                <Input
                                  variant="auth"
                                  fontSize="sm"
                                  ms="4px"
                                  type="text"
                                  placeholder="Contenido de Noticia"
                                  size="lg"
                                  id="form_subtitle"
                                  name="subtitle"
                                  onChange={handleChange}
                                  value={values.subtitle}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  <ErrorMessage name="subtitle" />
                                </div>
                              </>
                              <>
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="bold"
                                >
                                  Autor
                                </FormLabel>
                                <Input
                                  variant="auth"
                                  fontSize="sm"
                                  ms="4px"
                                  type="text"
                                  placeholder="Autor"
                                  size="lg"
                                  id="form_author"
                                  name="author"
                                  onChange={handleChange}
                                  value={values.author}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  <ErrorMessage name="author" />
                                </div>
                              </>
                              <>
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="bold"
                                >
                                  Categoria
                                </FormLabel>
                                <Select
                                  variant="auth"
                                  fontSize="sm"
                                  ms="4px"
                                  type="text"
                                  placeholder="Seleccione un tipo"
                                  size="lg"
                                  id="form_type"
                                  name="type"
                                  onChange={handleChange}
                                  value={values.type}
                                  border={true}
                                  borderWidth={1}
                                >
                                  <option value="Salud">Salud</option>
                                  <option value="Tecnologia">Tecnología</option>
                                  <option value="Finanzas">Finanzas</option>
                                  <option value="Forex">Forex</option>
                                </Select>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  <ErrorMessage name="type" />
                                </div>
                              </>
                              <>
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="bold"
                                >
                                  Visibilidad
                                </FormLabel>
                                <Select
                                  variant="auth"
                                  fontSize="sm"
                                  ms="4px"
                                  type="text"
                                  placeholder="Seleccione una visibilidad"
                                  size="lg"
                                  id="form_visibility"
                                  name="visibility"
                                  onChange={handleChange}
                                  value={values.visibility}
                                  border={true}
                                  borderWidth={1}
                                >
                                  <option value="PUBLIC">Pública</option>
                                  <option value="PRIVATE">Privada</option>
                                </Select>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  <ErrorMessage name="visibility" />
                                </div>
                              </>
                              <>
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="bold"
                                >
                                  URL Externa
                                </FormLabel>
                                <Input
                                  variant="auth"
                                  fontSize="sm"
                                  ms="4px"
                                  type="text"
                                  placeholder="URL de la noticia. Ej: https://... "
                                  size="lg"
                                  id="form_url"
                                  name="url"
                                  onChange={handleChange}
                                  value={values.url}
                                  style={{ marginBottom: "1rem" }}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  <ErrorMessage name="url" />
                                </div>
                              </>
                              <>
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="bold"
                                >
                                  Fecha de Publicación
                                </FormLabel>
                                <Input
                                  variant="auth"
                                  fontSize="sm"
                                  ms="4px"
                                  type="date"
                                  placeholder="Fecha de Publicación"
                                  size="lg"
                                  id="form_publishedDate"
                                  name="publishedDate"
                                  onChange={handleChange}
                                  defaultValue={""}
                                  value={values.publishedDate}
                                  style={{ marginBottom: "1rem" }}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  <ErrorMessage name="publishedDate" />
                                </div>
                              </>
                              <>
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="bold"
                                >
                                  URL Imagen
                                </FormLabel>
                                <Input
                                  variant="auth"
                                  fontSize="sm"
                                  ms="4px"
                                  type="text"
                                  placeholder="URL de la imagen. Ej: https://... "
                                  size="lg"
                                  id="form_portada"
                                  name="portada"
                                  onChange={handleChange}
                                  value={values.portada}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  <ErrorMessage name="portada" />
                                </div>
                              </>
                              <Flex
                                width={"100%"}
                                justifyContent="center"
                                mt={"1rem"}
                              >
                                <Suspense>
                                  <Img
                                    crossorigin="anonymous"
                                    alt={"preview"}
                                    src={values.portada}
                                    style={{
                                      width: "15rem",
                                      height: "12rem",
                                      objectFit: "contain",
                                    }}
                                    borderRadius={15}
                                    loader={<Loader size="large" />}
                                  />
                                </Suspense>
                              </Flex>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginBottom: "1rem",
                                }}
                              >
                                <ErrorMessage name="price" />
                              </div>

                              <Flex style={{ marginBottom: "2rem" }}>
                                <Button
                                  fontSize="14px"
                                  variant="dark"
                                  fontWeight="bold"
                                  w="40%"
                                  h="45"
                                  leftIcon={
                                    <AiOutlineClear color="#FFFFFF" size={21} />
                                  }
                                  backgroundColor={"#3182CE"}
                                  color={"white"}
                                  onClick={() => {
                                    resetForm();
                                  }}
                                >
                                  RESET
                                </Button>

                                <Button
                                  fontSize="14px"
                                  variant="dark"
                                  fontWeight="bold"
                                  w="60%"
                                  h="45"
                                  disabled={isSubmitting}
                                  onClick={handleSubmit}
                                  leftIcon={<RiSendPlaneFill />}
                                >
                                  ENVIAR NOTICIA
                                </Button>
                              </Flex>
                            </FormControl>
                          </form>

                          <Backdrop
                            open={isSubmitting || isLoading}
                            style={{
                              zIndex: 20,
                              color: "#fff",
                            }}
                          >
                            <CircularProgress
                              color="inherit"
                              style={{ color: "#03CFB3" }}
                            />
                          </Backdrop>
                        </>
                      )}
                    </Formik>
                  </Flex>
                </Tbody>
              </Table>
            </CardBody>
          </Card>
        </Flex>
      </Flex>

      <Card
        overflowX={{ sm: "scroll", xl: "scroll" }}
        my={{ base: "2rem", md: "1rem" }}
        p={{ base: "1rem", md: "2rem" }}
        w={{ base: "100%", md: "90%" }}
        mx="auto"
      >
        <CardHeader p="6px 0px 22px 0px">
          <Flex direction="column">
            <Text
              fontSize={{ base: "md", md: "xl" }}
              color={textColor}
              fontWeight="bold"
              pb=".5rem"
            >
              Todas las Noticias GENERALES
            </Text>
          </Flex>
        </CardHeader>
        <CardBody>
          <Table
            variant="simple"
            color={textColor}
            display={{ base: "block", md: "table" }}
          >
            <Thead display={{ base: "none", md: "table-header-group" }}>
              <div
                style={{
                  width: "100%",
                  height: "auto",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  onClick={() => getAllNews()}
                  leftIcon={<HiOutlineRefresh />}
                  display={{ base: "none", md: "flex" }}
                >
                  Actualizar
                </Button>
              </div>
              <Tr my=".8rem" pl="0px">
                <Th color="gray.400" borderColor={borderColor}>
                  Titulo/Subtitulo/Portada
                </Th>
                <Th color="gray.400" borderColor={borderColor}>
                  Fecha Publicaci n
                </Th>
                <Th color="gray.400" borderColor={borderColor}>
                  Fuente
                </Th>
                <Th color="gray.400" borderColor={borderColor}>
                  Hora
                </Th>
                <Th color="gray.400" borderColor={borderColor}>
                  URL Externa
                </Th>
                <Th color="gray.400" borderColor={borderColor}>
                  URL Portada
                </Th>
                <Th color="gray.400" borderColor={borderColor}>
                  Acciones
                </Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody display={{ base: "block", md: "table-row-group" }}>
              {news
                .filter((item) => !!item)
                .map((row, index, arr) => {
                  return (
                    <TablesTableRowNews
                      id={row.id}
                      name={row.name}
                      title={row.title}
                      subtitle={row.subtitle}
                      publishedDate={row.publishedDate}
                      type={row.type}
                      timestamp={row.timestamp}
                      url={row.url}
                      portada={row.coverImage}
                      getAllNoticias={getAllNews}
                      noticia={row}
                      isLast={index === arr.length - 1 ? true : false}
                      key={index}
                    />
                  );
                })}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default TablesNews;
