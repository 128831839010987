// @ts-check

import { DeleteObjectsCommand, ListObjectsV2Command } from "@aws-sdk/client-s3";
import awsExports from "aws-exports";

/**
 *
 * @param {import("@aws-sdk/client-s3").S3Client} s3Client - the S3 client
 * @param {string} folderID - the ID of the folder to delete
 */
export async function deleteTutorialBucket(s3Client, folderID) {
  const bucketName = awsExports.aws_user_files_s3_bucket;
  const listedObjects = await s3Client.send(
    new ListObjectsV2Command({
      Bucket: bucketName,
      Prefix: `public/${folderID}/`,
    }),
  );

  if (listedObjects.Contents.length === 0) return;

  const deleteCommandPayload = {
    Bucket: bucketName,
    Delete: { Objects: [] },
  };
  listedObjects.Contents.map(({ Key }) => {
    deleteCommandPayload.Delete.Objects.push({ Key });
  });

  await s3Client.send(new DeleteObjectsCommand(deleteCommandPayload));

  if (listedObjects.IsTruncated) await deleteTutorialBucket(s3Client, folderID);
}
