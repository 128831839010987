// @ts-check
import { API } from "aws-amplify";

/**
 * Send a notification to the expo server.
 * @param {Object} body - The object with the title, body, and url to send.
 * @param {string} body.title - The title of the notification.
 * @param {string} body.body - The body of the notification.
 * @param {string} [body.url] - The url to open when the notification is clicked.
 */
export const sendNotification = async (body) => {
  try {
    await API.post("apiNotifications", "/notifications", {
      body,
    });
  } catch (error) {
    console.error("Error al enviar la notificación:", error);
  }
};

export const notificateNews = async (news) => {
  await sendNotification({
    title: news.title,
    body: news.subtitle,
    url: news.url,
  });
};

/**
 * @param {Signal} signal - The signal to send the notification.
 */
export const notificateSignal = async (signal) => {
  await sendNotification({
    title: signal.title,
    body: `New signal for ${signal.pair}`,
    url: "bmaker://signals",
  });
};
