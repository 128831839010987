/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getNoticia = /* GraphQL */ `
  query GetNoticia($id: ID!) {
    getNoticia(id: $id) {
      id
      title
      subtitle
      description
      timestamp
      optionalImage
      source
      url
      author
      type
      publishedDate
      visibility
      coverImage
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listNoticias = /* GraphQL */ `
  query ListNoticias(
    $filter: ModelNoticiaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNoticias(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        subtitle
        description
        timestamp
        optionalImage
        source
        url
        author
        type
        publishedDate
        visibility
        coverImage
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTutorial = /* GraphQL */ `
  query GetTutorial($id: ID!) {
    getTutorial(id: $id) {
      id
      title
      subtitle
      description
      optionalImage
      source
      url
      publishedDate
      coverImage
      bucketID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTutorials = /* GraphQL */ `
  query ListTutorials(
    $filter: ModelTutorialFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTutorials(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        subtitle
        description
        optionalImage
        source
        url
        publishedDate
        coverImage
        bucketID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAuditedAccounts = /* GraphQL */ `
  query GetAuditedAccounts($id: ID!) {
    getAuditedAccounts(id: $id) {
      id
      name
      url
      gains
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAuditedAccounts = /* GraphQL */ `
  query ListAuditedAccounts(
    $filter: ModelAuditedAccountsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuditedAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        url
        gains
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStrategy = /* GraphQL */ `
  query GetStrategy($id: ID!) {
    getStrategy(id: $id) {
      id
      name
      url
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listStrategies = /* GraphQL */ `
  query ListStrategies(
    $filter: ModelStrategyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStrategies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        url
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEnvivos = /* GraphQL */ `
  query GetEnvivos($id: ID!) {
    getEnvivos(id: $id) {
      id
      title
      subtitle
      description
      timestamp
      publishedDate
      untilDate
      envivoDate
      envivoTime
      platform
      url
      visibility
      coverImage
      scheduleID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEnvivos = /* GraphQL */ `
  query ListEnvivos(
    $filter: ModelEnvivosFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEnvivos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        subtitle
        description
        timestamp
        publishedDate
        untilDate
        envivoDate
        envivoTime
        platform
        url
        visibility
        coverImage
        scheduleID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMentalidad = /* GraphQL */ `
  query GetMentalidad($id: ID!) {
    getMentalidad(id: $id) {
      id
      title
      subtitle
      description
      timestamp
      visibility
      optionalImage
      source
      url
      coverImage
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMentalidads = /* GraphQL */ `
  query ListMentalidads(
    $filter: ModelMentalidadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMentalidads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        subtitle
        description
        timestamp
        visibility
        optionalImage
        source
        url
        coverImage
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSignal = /* GraphQL */ `
  query GetSignal($id: ID!) {
    getSignal(id: $id) {
      id
      mode
      title
      subtitle
      timestamp
      pair
      operation
      position
      price
      takeProfit
      stopLoss
      visibility
      coverImage
      expiredAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSignals = /* GraphQL */ `
  query ListSignals(
    $filter: ModelSignalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSignals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        mode
        title
        subtitle
        timestamp
        pair
        operation
        position
        price
        takeProfit
        stopLoss
        visibility
        coverImage
        expiredAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getModo = /* GraphQL */ `
  query GetModo($id: ID!) {
    getModo(id: $id) {
      automatic
      lastUpdate
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listModos = /* GraphQL */ `
  query ListModos(
    $filter: ModelModoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listModos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        automatic
        lastUpdate
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCertificate = /* GraphQL */ `
  query GetCertificate($id: ID!) {
    getCertificate(id: $id) {
      id
      name
      documentUnsigned
      documentSigned
      isSigned
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCertificates = /* GraphQL */ `
  query ListCertificates(
    $filter: ModelCertificateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCertificates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        documentUnsigned
        documentSigned
        isSigned
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      username
      phone
      email
      isCommercial
      kyc {
        date
        __typename
      }
      session {
        osName
        deviceName
        deviceModel
        deviceBrand
        activeDate
        __typename
      }
      plan {
        type
        activationDate
        __typename
      }
      dateSigned
      expoToken
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        username
        phone
        email
        isCommercial
        kyc {
          date
          __typename
        }
        session {
          osName
          deviceName
          deviceModel
          deviceBrand
          activeDate
          __typename
        }
        plan {
          type
          activationDate
          __typename
        }
        dateSigned
        expoToken
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const usersByEmailAndUsername = /* GraphQL */ `
  query UsersByEmailAndUsername(
    $email: AWSEmail!
    $username: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByEmailAndUsername(
      email: $email
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        username
        phone
        email
        isCommercial
        kyc {
          date
          __typename
        }
        session {
          osName
          deviceName
          deviceModel
          deviceBrand
          activeDate
          __typename
        }
        plan {
          type
          activationDate
          __typename
        }
        dateSigned
        expoToken
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
