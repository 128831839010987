import React from "react";
import {
  Tr,
  Td,
  Flex,
  Text,
  Icon,
  useColorModeValue,
  Badge,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { FaEllipsisV } from "react-icons/fa";
import { Tooltip } from "@chakra-ui/react";
import { ItemContentSignals } from "components/Menu/ItemContentSignals";
import { API, graphqlOperation } from "aws-amplify";
import { deleteSignal, updateSignal } from "graphql/mutations";

import Swal from "sweetalert2";

function TablesProjectRow(props) {
  const {
    isLast,
    id,
    createdAt,
    operation,
    pair,
    price,
    time12h,
    date,
    signal,
    position,
    setUpdate,
    takeProfit,
    stopLoss,
  } = props;
  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  async function updateSignalHandler() {
    const { value: formValues } = await Swal.fire({
      title: "Editar Señal",
      html: `
        <div style="display: flex; flex-direction: column;">
          <div style="margin-bottom: 1rem;">
            <label for="swal-input1">Precio</label>
            <input id="swal-input1" class="swal2-input" placeholder="Precio" value="${price}">
          </div>
          <div style="margin-bottom: 1rem;">
            <label for="swal-input2">Take Profit</label>
            <input id="swal-input2" class="swal2-input" placeholder="Take Profit" value="${takeProfit}">
          </div>
          <div style="margin-bottom: 1rem;">
            <label for="swal-input3">Stop Loss</label>
            <input id="swal-input3" class="swal2-input" placeholder="Stop Loss" value="${stopLoss}">
          </div>
        </div>`,
      focusConfirm: false,
      preConfirm: () => {
        return [
          document.getElementById("swal-input1").value,
          document.getElementById("swal-input2").value,
          document.getElementById("swal-input3").value,
        ];
      },
    });

    if (formValues) {
      const [newPrice, newTakeProfit, newStopLoss] = formValues;

      const signalDetails = {
        id: id,
        price: newPrice,
        takeProfit: newTakeProfit,
        stopLoss: newStopLoss,
      };

      try {
        await API.graphql(
          graphqlOperation(updateSignal, {
            input: signalDetails,
          }),
        );
        Swal.fire("Actualizada!", "La señal ha sido actualizada.", "success");
        setUpdate(true);
      } catch (error) {
        Swal.fire("Error", "La señal no ha sido actualizada.", "error");
        console.log(error);
      }
    }
  }

  async function deleteSignalHandler() {
    Swal.fire({
      title: "Estas seguro?",
      text: "Esta operación no se puede revertir",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Borrar",
      cancelButtonColor: "#F56565",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await API.graphql({
            query: deleteSignal,
            variables: {
              input: {
                id: signal.id,
              },
            },
          });
          Swal.fire(
            "Eliminada!",
            "La señal ha sido eliminada.",
            "success",
          ).finally(() => {
            setUpdate(true);
          });
        } catch (error) {
          Swal.fire("Error", "La señal no ha sido eliminada.", "error");
        }
      }
    });
  }

  return (
    <Tr>
      <Td
        minWidth={{ sm: "100px" }}
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : null}
      >
        <Flex alignItems="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          {pair.toLowerCase() == "audcad" && (
            <Tooltip hasArrow label={"Identificador: " + id} fontSize="sm">
              <Image
                src={require(`../../assets/img/pairs/audcad.png`)}
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: 10,
                  marginRight: 8,
                }}
              />
            </Tooltip>
          )}

          {pair.toLowerCase() == "usdcad" && (
            <Tooltip hasArrow label={"Identificador: " + id} fontSize="sm">
              <Image
                src={require(`../../assets/img/pairs/usdcad.png`)}
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: 10,
                  marginRight: 8,
                }}
              />
            </Tooltip>
          )}

          {pair.toLowerCase() == "nzdcad" && (
            <Tooltip hasArrow label={"Identificador: " + id} fontSize="sm">
              <Image
                src={require(`../../assets/img/pairs/nzdcad.png`)}
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: 10,
                  marginRight: 8,
                }}
              />
            </Tooltip>
          )}

          {pair.toLowerCase() == "eurusd" && (
            <Tooltip hasArrow label={"Identificador: " + id} fontSize="sm">
              <Image
                src={require(`../../assets/img/pairs/eurusd.png`)}
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: 10,
                  marginRight: 8,
                }}
              />
            </Tooltip>
          )}

          {pair.toLowerCase() == "cadchf" && (
            <Tooltip hasArrow label={"Identificador: " + id} fontSize="sm">
              <Image
                src={require(`../../assets/img/pairs/cadchf.png`)}
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: 10,
                  marginRight: 8,
                }}
              />
            </Tooltip>
          )}
          <Text
            fontSize="md"
            color={titleColor}
            fontWeight="bold"
            minWidth="100%"
          >
            {pair && pair.toUpperCase()}
          </Text>
        </Flex>
      </Td>
      <Td borderBottom={isLast ? "none" : null} borderColor={borderColor}>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          ${price}
        </Text>
      </Td>
      <Td borderBottom={isLast ? "none" : null} borderColor={borderColor}>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          ${takeProfit}
        </Text>
      </Td>
      <Td borderBottom={isLast ? "none" : null} borderColor={borderColor}>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          ${stopLoss}
        </Text>
      </Td>
      <Td borderBottom={isLast ? "none" : null} borderColor={borderColor}>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {time12h}
        </Text>
      </Td>
      <Td borderBottom={isLast ? "none" : null} borderColor={borderColor}>
        <Tooltip
          hasArrow
          label={"Fecha de Lanzamiento por parte nuestra: " + createdAt}
          fontSize="sm"
        >
          <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
            {date}
          </Text>
        </Tooltip>
      </Td>
      <Td borderBottom={isLast ? "none" : null} borderColor={borderColor}>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {operation ? operation : ""}
        </Text>
      </Td>
      <Td
        borderColor={borderColor}
        borderBottom={isLast ? "none" : null}
        style={{ height: "100%", justifyContent: "center" }}
      >
        <Badge
          bg={position == "BUY" ? "green.400" : "red.400"}
          color={position == "BUY" ? "white" : "white"}
          fontSize="16px"
          p="3px 10px"
          borderRadius="8px"
        >
          {position == "BUY" ? "Comprar" : "Vender"}
        </Badge>
      </Td>
      {/* <Td
        borderColor={borderColor}
        borderBottom={isLast ? "none" : null}
        style={{ height: "100%", justifyContent: "center" }}
      >
        <Badge
          bg={isManual ? "purple.400" : "yellow.400"}
          color={isManual ? "white" : "white"}
          fontSize="16px"
          p="3px 10px"
          borderRadius="8px"
        >
          {isManual ? "Manual" : "Automática"}
        </Badge>
      </Td> */}
      <Td borderBottom={isLast ? "none" : null} borderColor={borderColor}>
        <Menu>
          <MenuButton marginLeft={"1rem"}>
            <Icon as={FaEllipsisV} color="gray.400" cursor="pointer" />
          </MenuButton>
          <MenuList p="16px 8px">
            <Flex flexDirection="column">
              <MenuItem
                borderRadius="8px"
                mb="10px"
                onClick={deleteSignalHandler}
              >
                <ItemContentSignals
                  type="delete"
                  onPress={deleteSignalHandler}
                />
              </MenuItem>
              <MenuItem
                borderRadius="8px"
                mb="10px"
                onClick={updateSignalHandler}
              >
                <ItemContentSignals type="edit" onPress={updateSignalHandler} />
              </MenuItem>
            </Flex>
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  );
}

export default TablesProjectRow;
