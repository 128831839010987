// @ts-check
import { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Button,
  Thead,
  Tr,
  useColorModeValue,
  Card,
} from "@chakra-ui/react";

import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import TablesTableRow from "components/Tables/TablesTableRow";

import { HiOutlineRefresh } from "react-icons/hi";
import { listUsers } from "graphql/queries";
import { Backdrop, CircularProgress } from "@material-ui/core";

function Tables() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  async function getAllUsers() {
    setIsLoading(true);
    try {
      const getAllUsersData = await (
        await API.graphql(graphqlOperation(listUsers))
      ).data;
      if (getAllUsersData.listUsers.items)
        setUsers(
          getAllUsersData.listUsers.items.filter((item) => !item._deleted),
        );
      setIsLoading(false);
    } catch (error) {
      console.log("Error actualizando usuarios", error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getAllUsers();
  }, []);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "scroll" }} p="1rem">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Todos los usuarios
          </Text>
        </CardHeader>
        <CardBody style={{ height: "60rem" }}>
          <Table variant="simple" color={textColor}>
            <Thead>
              <div
                style={{
                  width: "100%",
                  height: "auto",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  leftIcon={<HiOutlineRefresh />}
                  onClick={() => getAllUsers()}
                >
                  ACTUALIZAR
                </Button>
              </div>
              <Tr my=".8rem" pl="0px" color="gray.400">
                <Th borderColor={borderColor} color="gray.400">
                  ID
                </Th>
                <Th pl="0px" borderColor={borderColor} color="gray.400">
                  Nombre / Email
                </Th>
                <Th borderColor={borderColor} color="gray.400">
                  Fecha de Registro / Telefono
                </Th>
                <Th borderColor={borderColor} color="gray.400">
                  Sesión Activa
                </Th>
                <Th borderColor={borderColor} color="gray.400">
                  Notificaciones
                </Th>
                <Th borderColor={borderColor} color="gray.400">
                  Plan Actual
                </Th>
                <Th borderColor={borderColor} color="gray.400">
                  Fecha de Activación
                </Th>
                <Th borderColor={borderColor} color="gray.400">
                  KYC
                </Th>
                <Th borderColor={borderColor} color="gray.400">
                  Ha firmado descargos?
                </Th>
                <Th borderColor={borderColor} color="gray.400">
                  ACCIONES
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {users.length > 0 &&
                users.map((row, index, arr) => {
                  if (row) {
                    const sanityUser = {
                      createdAt: row.createdAt,
                      email: row.email,
                      expoToken: row.expoToken || "NO TIENE",
                      plan: row.plan?.type || false,
                      hasPurchasedSomething:
                        row.hasPurchasedSomething == null
                          ? false
                          : row.hasPurchasedSomething,
                      hasReferred:
                        row.hasReferred == null ? false : row.hasReferred,
                      id: row.id,
                      isReferred:
                        row.isReferred == null ? false : row.isReferred,
                      name: row.name,
                      phone: row.phone,
                      referredBy:
                        row.referredBy == null ? "NO TIENE" : row.referredBy,
                      totalReferred:
                        row.totalReferred == null ? 0 : row.totalReferred,
                      totalReward:
                        row.totalReward == null ? 0 : row.totalReward,
                      isCompletedKYC: row.isCompletedKYC,
                      updatedAt: row.updatedAt,
                      username: row.username,
                    };

                    console.dir({ row, sanityUser });

                    return (
                      <TablesTableRow
                        userComplete={row}
                        ID={sanityUser.id}
                        name={sanityUser.name}
                        email={sanityUser.email}
                        phone_number={sanityUser.phone}
                        username={sanityUser.username}
                        expoToken={row.expoToken}
                        currentPlan={row.plan}
                        dateSigned={row.dateSigned}
                        date_register={new Date(
                          sanityUser.createdAt,
                        ).toLocaleString("co-CO")}
                        isCompletedKYC={sanityUser.isCompletedKYC}
                        isLast={index === arr.length - 1 ? true : false}
                        key={index}
                        getAllUsers={getAllUsers}
                        session={row.session}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
      <Backdrop
        open={isLoading}
        style={{
          zIndex: 20,
          color: "#fff",
        }}
      >
        <CircularProgress color="inherit" style={{ color: "#03CFB3" }} />
      </Backdrop>
    </Flex>
  );
}

export default Tables;
