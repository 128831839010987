// @ts-check
import { API } from "aws-amplify";
import { nanoid } from "nanoid";

/**
 * Schedule a notification to using the EventBridge Scheduler.
 * @param {string} scheduleID - The ID of the schedule.
 * @param {string} eventName - The name of the event to send the notification.
 * @param {Date} dateTime - The date and time to send the notification.
 * @param {string} [action] - The action to perform.
 * @returns Promise
 */
export const scheduleNotification = async (
  scheduleID,
  eventName,
  dateTime,
  action,
) => {
  try {
    const id = action === "update" ? scheduleID : nanoid();
    await API.post("apiNotifications", "/notifications/schedule", {
      body: {
        scheduleID: id,
        eventName,
        date: parseDate(dateTime),
        action,
      },
    });
    return id;
  } catch (error) {
    console.error("Error al programar la notificación:", error);
  }
};

export const rescheduleNotification = async (
  scheduleID,
  eventName,
  dateTime,
) => {
  return scheduleNotification(scheduleID, eventName, dateTime, "update");
};

/**
 * Parse a date to the format expected by the EventBridge Scheduler and Colombia timezone.
 * @param {Date} date - The date to parse.
 * @returns Date
 */
const parseDate = (date) => {
  const timeZone = "America/Bogota";
  // Use Intl.DateTimeFormat to format the date in the desired timezone
  const formatter = new Intl.DateTimeFormat("es-CO", {
    timeZone,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  });

  const parts = formatter.formatToParts(date);

  const year = parts.find((p) => p.type === "year").value;
  const month = parts.find((p) => p.type === "month").value;
  const day = parts.find((p) => p.type === "day").value;
  const hour = parts.find((p) => p.type === "hour").value;
  const minute = parts.find((p) => p.type === "minute").value;
  const second = parts.find((p) => p.type === "second").value;

  return `${year}-${month}-${day}T${hour}:${minute}:${second}`;
};
