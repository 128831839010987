// @ts-check
import { useState, useEffect, useRef, Suspense } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Select,
  Table,
  Tbody,
  Text,
  Input,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Card,
} from "@chakra-ui/react";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { HiOutlineRefresh } from "react-icons/hi";
import { RiSendPlaneFill } from "react-icons/ri";
import { AiOutlineClear } from "react-icons/ai";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { Formik, ErrorMessage } from "formik";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { listEnvivos } from "graphql/queries";
import { Loader } from "@aws-amplify/ui-react";
import { Img } from "react-image";
import { createEnvivos } from "graphql/mutations";
import TablesTableRowEnvivos from "components/Tables/TablesTableRowEnvivos";
import { scheduleNotification } from "notifications/NotificatorScheduler";
import { validateLive } from "types/Live/ValidateLive";

function TablesEnvivos() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const formRef = useRef();

  const [envivos, setEnvivos] = useState([]);

  const [update, setUpdate] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const getAllEnvivos = async () => {
    setIsLoading(true);
    try {
      const res = await API.graphql(graphqlOperation(listEnvivos));
      if (res?.data?.listEnvivos?.items) {
        setEnvivos(
          res.data.listEnvivos.items
            .filter((item) => !item._deleted && !!item)
            .sort((a, b) => {
              const aDate = new Date(`${a.envivoDate}T${a.envivoTime}`);
              const bDate = new Date(`${b.envivoDate}T${b.envivoTime}`);
              return bDate.getTime() - aDate.getTime();
            }),
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (update) {
      getAllEnvivos();
      setUpdate(false);
    }
  }, [update]);

  return (
    <Flex
      direction="column"
      pt={{ base: "120px", md: "75px" }}
      style={{ justifyContent: "center", alignItems: "center" }}
    >
      <Flex
        direction={{ lg: "row", xl: "row", sm: "column", md: "column" }}
        display="flex"
      >
        <Flex style={{ justifyContent: "center", alignItems: "center" }}>
          <Card
            overflowX={{ sm: "scroll", xl: "hidden" }}
            style={{ width: "40rem", padding: "1rem" }}
            pb="0px"
          >
            <CardHeader p="0px 0px 0px 0px">
              <Flex direction="column" w={{ sm: "100%" }}>
                <Text
                  fontSize="xl"
                  color={textColor}
                  fontWeight="bold"
                  pb=".5rem"
                >
                  Enviar Envivo
                </Text>
              </Flex>
            </CardHeader>
            <CardBody>
              <Table variant="simple" color={textColor}>
                <Thead></Thead>
                <Tbody>
                  <Flex
                    style={{
                      width: "100%",
                      height: "auto",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Formik
                      id="formik"
                      innerRef={formRef}
                      initialValues={{
                        title: "",
                        subtitle: "",
                        description: "",
                        visibility: "PUBLIC",
                        url: "",
                        untilDate: "",
                        coverImage: "",
                        envivoDate: "",
                        envivoTime: "",
                        platform: "",
                      }}
                      validationSchema={Yup.object({
                        title: Yup.string().required("Requerido"),
                        subtitle: Yup.string().required("Requerido"),
                        description: Yup.string().required("Requerido"),
                        platform: Yup.string().required("Requerido"),
                        visibility: Yup.string().required("Requerido"),
                        untilDate: Yup.date().required("Requerido"),
                        url: Yup.string().required("Requerido"),
                        coverImage: Yup.string().required("Requerido"),
                        envivoDate: Yup.date().required("Requerido"),
                        envivoTime: Yup.string().required("Requerido"),
                      })}
                      validateOnChange={false}
                      validate={validateLive}
                      onSubmit={async (values, { resetForm }) => {
                        try {
                          const scheduleID = await scheduleNotification(
                            undefined,
                            values.title,
                            new Date(
                              `${values.envivoDate}T${values.envivoTime}`,
                            ),
                          );

                          await API.graphql(
                            graphqlOperation(createEnvivos, {
                              input: {
                                ...values,
                                publishedDate: new Date().toLocaleDateString(
                                  "es-CO",
                                ),
                                timestamp: new Date().toLocaleTimeString(
                                  "es-CO",
                                  {
                                    hour12: false,
                                  },
                                ),
                                scheduleID,
                              },
                            }),
                          );

                          setIsLoading(false);

                          Swal.fire({
                            title: "Envivo Creado",
                            text: "Por favor revisa la app",
                            icon: "success",
                            willClose: () => {
                              resetForm();
                              getAllEnvivos();
                            },
                          });
                        } catch (error) {
                          setIsLoading(false);
                          Swal.fire(
                            "Revisa e intenta de nuevo",
                            error.message,
                            "error",
                          );
                          console.log(error);
                        }
                      }}
                    >
                      {({
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        resetForm,
                        values,
                      }) => (
                        <>
                          <form onSubmit={handleSubmit}>
                            <FormControl>
                              <>
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="bold"
                                >
                                  Titulo
                                </FormLabel>
                                <Input
                                  variant="auth"
                                  fontSize="sm"
                                  ms="4px"
                                  type="text"
                                  placeholder="Titulo de Envivo"
                                  size="lg"
                                  id="form_title"
                                  name="title"
                                  onChange={handleChange}
                                  value={values.title}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "1rem",
                                    color: "red",
                                    fontSize: "0.8rem",
                                  }}
                                >
                                  <ErrorMessage name="title" />
                                </div>
                              </>
                              <>
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="bold"
                                >
                                  Subtitulo
                                </FormLabel>
                                <Input
                                  variant="auth"
                                  fontSize="sm"
                                  ms="4px"
                                  type="text"
                                  placeholder="Subtitulo de Envivo"
                                  size="lg"
                                  id="form_subtitle"
                                  name="subtitle"
                                  onChange={handleChange}
                                  value={values.subtitle}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "1rem",
                                    color: "red",
                                    fontSize: "0.8rem",
                                  }}
                                >
                                  <ErrorMessage name="subtitle" />
                                </div>
                              </>
                              <>
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="bold"
                                >
                                  Descripcion
                                </FormLabel>
                                <Input
                                  variant="auth"
                                  fontSize="sm"
                                  ms="4px"
                                  placeholder="Descripcion del Envivo"
                                  size="lg"
                                  id="form_description"
                                  name="description"
                                  onChange={handleChange}
                                  value={values.description}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "1rem",
                                    color: "red",
                                    fontSize: "0.8rem",
                                  }}
                                >
                                  <ErrorMessage name="description" />
                                </div>
                              </>
                              <>
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="bold"
                                >
                                  Plataforma
                                </FormLabel>
                                <Select
                                  variant="auth"
                                  fontSize="sm"
                                  ms="4px"
                                  placeholder="Seleccione un tipo"
                                  size="lg"
                                  id="form_platform"
                                  name="platform"
                                  onChange={handleChange}
                                  value={values.platform}
                                  borderWidth={1}
                                >
                                  <option value="ZOOM">Zoom</option>
                                  <option value="GOOGLE">Google Meet</option>
                                  <option value="YOUTUBE">Youtube</option>
                                  <option value="OTRO">Otro</option>
                                </Select>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "1rem",
                                    color: "red",
                                    fontSize: "0.8rem",
                                  }}
                                >
                                  <ErrorMessage name="platform" />
                                </div>
                              </>

                              <>
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="bold"
                                >
                                  Visibilidad
                                </FormLabel>
                                <Select
                                  variant="auth"
                                  fontSize="sm"
                                  ms="4px"
                                  size="lg"
                                  id="form_visibility"
                                  name="visibility"
                                  onChange={handleChange}
                                  value={values.visibility}
                                  borderWidth={1}
                                >
                                  <option value="PUBLIC">Público</option>
                                  <option value="PRIVATE">Privado</option>
                                </Select>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "1rem",
                                    color: "red",
                                    fontSize: "0.8rem",
                                  }}
                                >
                                  <ErrorMessage name="visibility" />
                                </div>
                              </>

                              <>
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="bold"
                                >
                                  URL del Envivo
                                </FormLabel>
                                <Input
                                  variant="auth"
                                  fontSize="sm"
                                  ms="4px"
                                  type="text"
                                  placeholder="URL del Envivo. Ej: https://... "
                                  size="lg"
                                  id="form_externalUrl"
                                  name="url"
                                  onChange={handleChange}
                                  value={values.url}
                                  style={{ marginBottom: "1rem" }}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "1rem",
                                    color: "red",
                                    fontSize: "0.8rem",
                                  }}
                                >
                                  <ErrorMessage name="url" />
                                </div>
                              </>
                              <>
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="bold"
                                >
                                  Disponible hasta
                                </FormLabel>
                                <Input
                                  variant="auth"
                                  fontSize="sm"
                                  ms="4px"
                                  type="date"
                                  placeholder="Disponible hasta"
                                  size="lg"
                                  id="form_untilDate"
                                  name="untilDate"
                                  onChange={handleChange}
                                  defaultValue={""}
                                  value={values.untilDate}
                                  style={{ marginBottom: "1rem" }}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "1rem",
                                    color: "red",
                                    fontSize: "0.8rem",
                                  }}
                                >
                                  <ErrorMessage name="untilDate" />
                                </div>
                              </>
                              <>
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="bold"
                                >
                                  Dia del Envivo
                                </FormLabel>
                                <Input
                                  variant="auth"
                                  fontSize="sm"
                                  ms="4px"
                                  type="date"
                                  placeholder="Fecha del Envivo"
                                  size="lg"
                                  id="form_envivoDate"
                                  name="envivoDate"
                                  onChange={handleChange}
                                  defaultValue={""}
                                  value={values.envivoDate}
                                  style={{ marginBottom: "1rem" }}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "1rem",
                                    color: "red",
                                    fontSize: "0.8rem",
                                  }}
                                >
                                  <ErrorMessage name="envivoDate" />
                                </div>
                              </>

                              <>
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="bold"
                                >
                                  Hora del Envivo
                                </FormLabel>
                                <Input
                                  variant="auth"
                                  fontSize="sm"
                                  ms="4px"
                                  type="time"
                                  placeholder="Hora del Envivo"
                                  size="lg"
                                  id="form_envivoTime"
                                  name="envivoTime"
                                  onChange={handleChange}
                                  defaultValue={""}
                                  value={values.envivoTime}
                                  style={{ marginBottom: "1rem" }}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "1rem",
                                    color: "red",
                                    fontSize: "0.8rem",
                                  }}
                                >
                                  <ErrorMessage name="envivoTime" />
                                </div>
                              </>
                              <>
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="bold"
                                >
                                  URL Imagen
                                </FormLabel>
                                <Input
                                  variant="auth"
                                  fontSize="sm"
                                  ms="4px"
                                  type="text"
                                  placeholder="URL de la imagen. Ej: https://... "
                                  size="lg"
                                  id="form_coverImage"
                                  name="coverImage"
                                  onChange={handleChange}
                                  value={values.coverImage}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "1rem",
                                    color: "red",
                                    fontSize: "0.8rem",
                                  }}
                                >
                                  <ErrorMessage name="coverImage" />
                                </div>
                              </>
                              <Flex
                                width={"100%"}
                                justifyContent="center"
                                mt={"1rem"}
                              >
                                <Suspense>
                                  <Img
                                    crossorigin="anonymous"
                                    alt={"preview"}
                                    src={values.coverImage}
                                    style={{
                                      width: "15rem",
                                      height: "12rem",
                                      objectFit: "contain",
                                    }}
                                    loader={
                                      <Loader
                                        onPointerEnterCapture={() => null}
                                        onPointerLeaveCapture={() => null}
                                        size="large"
                                      />
                                    }
                                  />
                                </Suspense>
                              </Flex>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginBottom: "1rem",
                                }}
                              >
                                <ErrorMessage name="price" />
                              </div>

                              <Flex style={{ marginBottom: "2rem" }}>
                                <Button
                                  fontSize="14px"
                                  variant="dark"
                                  fontWeight="bold"
                                  w="40%"
                                  h="45"
                                  leftIcon={
                                    <AiOutlineClear color="#FFFFFF" size={21} />
                                  }
                                  backgroundColor={"#3182CE"}
                                  color={"white"}
                                  onClick={() => {
                                    resetForm();
                                  }}
                                >
                                  RESET
                                </Button>

                                <Button
                                  fontSize="14px"
                                  variant="dark"
                                  fontWeight="bold"
                                  w="60%"
                                  h="45"
                                  disabled={isSubmitting}
                                  // @ts-ignore
                                  onClick={handleSubmit}
                                  leftIcon={<RiSendPlaneFill />}
                                >
                                  ENVIAR ENVIVO
                                </Button>
                              </Flex>
                            </FormControl>
                          </form>

                          <Backdrop
                            open={isSubmitting || isLoading}
                            style={{
                              zIndex: 20,
                              color: "#fff",
                            }}
                          >
                            <CircularProgress
                              color="inherit"
                              style={{ color: "#03CFB3" }}
                            />
                          </Backdrop>
                        </>
                      )}
                    </Formik>
                  </Flex>
                </Tbody>
              </Table>
            </CardBody>
          </Card>
        </Flex>
      </Flex>

      <Card
        overflowX={{ sm: "scroll", xl: "scroll" }}
        my={{ base: "2rem", md: "1rem" }}
        p={{ base: "1rem", md: "2rem" }}
        w={{ base: "100%", md: "90%" }}
        mx="auto"
      >
        <CardHeader p="6px 0px 22px 0px">
          <Flex direction="column">
            <Text
              fontSize={{ base: "md", md: "xl" }}
              color={textColor}
              fontWeight="bold"
              pb=".5rem"
            >
              Todos los Envivos
            </Text>
          </Flex>
        </CardHeader>
        <CardBody>
          <Table
            variant="simple"
            color={textColor}
            display={{ base: "block", md: "table" }}
          >
            <Thead display={{ base: "none", md: "table-header-group" }}>
              <div
                style={{
                  width: "100%",
                  height: "auto",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  onClick={() => getAllEnvivos()}
                  leftIcon={<HiOutlineRefresh />}
                  display={{ base: "none", md: "flex" }}
                >
                  Actualizar
                </Button>
              </div>
              <Tr my=".8rem" pl="0px">
                <Th color="gray.400" borderColor={borderColor}>
                  Portada/Titulo/Subtitulo
                </Th>
                {/* <Th color="gray.400" borderColor={borderColor}>
                  Subtitulo
                </Th> */}
                <Th color="gray.400" borderColor={borderColor}>
                  Plataforma
                </Th>
                <Th color="gray.400" borderColor={borderColor}>
                  URL Externa
                </Th>
                <Th color="gray.400" borderColor={borderColor}>
                  Visibilidad
                </Th>
                <Th color="gray.400" borderColor={borderColor}>
                  Fecha Publicación
                </Th>
                <Th color="gray.400" borderColor={borderColor}>
                  Disponible Hasta
                </Th>
                <Th color="gray.400" borderColor={borderColor}>
                  Dia del Envivo / Hora
                </Th>
                <Th color="gray.400" borderColor={borderColor}>
                  Acciones
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {envivos.map((row, index) => {
                return (
                  <TablesTableRowEnvivos
                    id={row.id}
                    live={row}
                    setUpdate={setUpdate}
                    isLast={index === envivos.length - 1}
                    key={index}
                  />
                );
              })}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default TablesEnvivos;
