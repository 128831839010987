// @ts-check
import { API, graphqlOperation } from "aws-amplify";
import { useEffect, useState } from "react";

export default function useGraphqlOperation(operation) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(true);

  useEffect(() => {
    if (!update) return;
    setLoading(true);
    API.graphql(graphqlOperation(operation))
      .then((result) => {
        setData(result?.data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
        setUpdate(false);
      });
  }, [operation, update]);

  const updateData = () => setUpdate(true);

  return { data, error, loading, updateData };
}
