// @ts-check
const websiteRegex =
  /^(https?:\/\/)?([a-zA-Z0-9_-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/\S*)?$/;
const imageRegex = /\.(jpeg|jpg|png|gif|webp)$/i;

/**
 * Validate live
 * @param {CreateEnvivoInput} live
 * @returns {Object} errors
 **/
export const validateLive = (live) => {
  const errors = {};

  if (new Date(`${live.envivoDate}T${live.envivoTime}`) < new Date()) {
    errors.envivoDate = "Fecha no puede ser en el pasado";
    errors.envivoTime = "Fecha no puede ser en el pasado";
  }

  if (new Date(`${live.untilDate}T23:59:59`) < new Date()) {
    errors.untilDate = "Fecha no puede ser en el pasado";
  }

  if (!websiteRegex.test(live.url)) {
    errors.url = "Formato de URL no válido. Debe iniciar por https://";
  }

  if (!imageRegex.test(live.coverImage)) {
    errors.coverImage = "Formato de imagen no válido.";
  }

  return errors;
};
