import React, { useState, useEffect, useRef } from "react";
import { API, graphqlOperation } from "aws-amplify";
// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Select,
  Table,
  Tbody,
  Text,
  Input,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Card,
} from "@chakra-ui/react";
// Custom components

import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import TablesProjectRow from "components/Tables/TablesProjectRow";
import { HiOutlineRefresh } from "react-icons/hi";
import { RiSendPlaneFill } from "react-icons/ri";
import { AiOutlineClear } from "react-icons/ai";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { Formik, ErrorMessage } from "formik";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { listSignals } from "graphql/queries";
import { createSignal } from "graphql/mutations";
import { notificateSignal, sendNotification } from "notifications/Notificator";

function Manual() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  const formRef = useRef();

  const [signals, setSignals] = useState([]);
  const [update, setUpdate] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  async function getAllNotifications() {
    console.log("obteniendo notificaciones...");
    setIsLoading(true);
    try {
      const getAllNotificationsData = await (
        await API.graphql(graphqlOperation(listSignals))
      ).data;
      if (getAllNotificationsData.listSignals.items != null) {
        const onlyValidNotifications =
          getAllNotificationsData.listSignals.items.filter(
            (item) => !item._deleted,
          );
        setSignals(onlyValidNotifications);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("Error actualizando usuarios", error);
      setIsLoading(false);
    }
  }
  useEffect(() => {
    if (update) {
      getAllNotifications();
      setUpdate(false);
    }
  }, [update]);

  return (
    <Flex
      direction="column"
      pt={{ base: "120px", md: "75px" }}
      style={{ justifyContent: "center", alignItems: "center" }}
    >
      <Flex
        direction={{ lg: "row", xl: "row", sm: "column", md: "column" }}
        display="flex"
      >
        <Flex style={{ justifyContent: "center", alignItems: "center" }}>
          <Card
            overflowX={{ sm: "scroll", xl: "hidden" }}
            style={{ width: "40rem", padding: "1rem" }}
            pb="0px"
          >
            <CardHeader p="0px 0px 0px 0px">
              <Flex direction="column" w={{ sm: "100%" }}>
                <Text
                  fontSize="xl"
                  color={textColor}
                  fontWeight="bold"
                  pb=".5rem"
                >
                  Enviar señal
                </Text>
              </Flex>
            </CardHeader>
            <CardBody>
              <Table variant="simple" color={textColor}>
                <Thead></Thead>
                <Tbody>
                  <Flex
                    style={{
                      width: "100%",
                      height: "auto",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Formik
                      id="formik"
                      innerRef={formRef}
                      initialValues={{
                        pair: "",
                        type: "",
                        price: 0,
                        position: "",
                        takeProfit: 0,
                        stopLoss: 0,
                      }}
                      validationSchema={Yup.object({
                        pair: Yup.string().required("Required"),
                        type: Yup.string().required("Required"),
                        price: Yup.number().required("Required"),
                        takeProfit: Yup.number().required("Required"),
                        stopLoss: Yup.number().required("Required"),
                        position: Yup.string().required("Required"),
                      })}
                      onSubmit={async (values, { resetForm }) => {
                        const payload = {
                          title: "You have a new Signal, please check the app",
                          pair: values.pair,
                          operation: values.type,
                          mode: "MANUAL",
                          price: Number(values.price),
                          takeProfit: Number(values.takeProfit),
                          stopLoss: Number(values.stopLoss),
                          position: values.position,
                          timestamp: parseInt(new Date().getTime().toString()),
                          visibility: "PUBLIC",
                        };

                        try {
                          await notificateSignal(payload);

                          await API.graphql(
                            graphqlOperation(createSignal, {
                              input: payload,
                            }),
                          );

                          Swal.fire({
                            title: "Señal enviada",
                            text: "Los suscriptores han sido notificados",
                            icon: "success",
                            willClose: () => {
                              resetForm();
                              setUpdate(true);
                            },
                          });
                        } catch (error) {
                          Swal.fire(
                            "Revisa e intenta de nuevo",
                            error.message,
                            "error",
                          );
                        }
                      }}
                    >
                      {({
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        resetForm,
                        values,
                      }) => (
                        <>
                          <form onSubmit={handleSubmit}>
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="bold"
                              >
                                Par Operado
                              </FormLabel>
                              <Select
                                variant="auth"
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="Seleccione un par"
                                size="lg"
                                id="form_pair"
                                name="pair"
                                onChange={handleChange}
                                value={values.pair}
                                border={true}
                                borderWidth={1}
                              >
                                <option value="AUDCAD">AUDCAD</option>
                                <option value="CADCHF">CADCHF</option>
                                <option value="EURUSD">EURUSD</option>
                                <option value="NZDCAD">NZDCAD</option>
                                <option value="USDCAD">USDCAD</option>
                              </Select>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginBottom: "1rem",
                                }}
                              >
                                <ErrorMessage
                                  name="pair"
                                  style={{ color: "red" }}
                                />
                              </div>

                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="bold"
                              >
                                Precio de entrada
                              </FormLabel>
                              <Input
                                variant="auth"
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="1.00420"
                                size="lg"
                                id="form_price"
                                name="price"
                                onChange={handleChange}
                                value={values.price}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginBottom: "1rem",
                                }}
                              >
                                <ErrorMessage name="price" />
                              </div>

                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="bold"
                              >
                                Take profit (TP)
                              </FormLabel>
                              <Input
                                variant="auth"
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="1.00420"
                                size="lg"
                                id="form_takeProfit"
                                name="takeProfit"
                                onChange={handleChange}
                                value={values.takeProfit}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginBottom: "1rem",
                                }}
                              >
                                <ErrorMessage name="takeProfit" />
                              </div>

                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="bold"
                              >
                                Stop loss (SL)
                              </FormLabel>
                              <Input
                                variant="auth"
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="1.00420"
                                size="lg"
                                id="form_stopLoss"
                                name="stopLoss"
                                onChange={handleChange}
                                value={values.stopLoss}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginBottom: "1rem",
                                }}
                              >
                                <ErrorMessage name="stopLoss" />
                              </div>

                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="bold"
                              >
                                Tipo de Orden{" "}
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="thin"
                                >
                                  (Si es para Abrir o Cerrar)
                                </FormLabel>
                              </FormLabel>
                              <Select
                                variant="auth"
                                fontSize="sm"
                                ms="4px"
                                type="type"
                                id="form_type"
                                placeholder="Seleccione un tipo de orden"
                                size="lg"
                                name="type"
                                onChange={handleChange}
                                value={values.type}
                                border={true}
                                borderWidth={1}
                              >
                                <option value="OPEN">New</option>
                                <option value="CLOSE">Close</option>
                              </Select>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginBottom: "1rem",
                                }}
                              >
                                <ErrorMessage
                                  name="type"
                                  style={{ paddingTop: "0" }}
                                />
                              </div>

                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="bold"
                              >
                                Tipo de Posición{" "}
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="thin"
                                >
                                  (Si es para Compra o Venta)
                                </FormLabel>
                              </FormLabel>
                              <Select
                                variant="auth"
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="Seleccione un tipo de posición"
                                size="lg"
                                id="form_position"
                                name="position"
                                onChange={handleChange}
                                value={values.position}
                                border={true}
                                borderWidth={1}
                              >
                                <option value="BUY">Buy</option>
                                <option value="SELL">Sell</option>
                              </Select>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginBottom: "1rem",
                                }}
                              >
                                <ErrorMessage
                                  name="email"
                                  style={{ paddingTop: "0" }}
                                />
                              </div>
                              <Flex style={{ marginBottom: "2rem" }}>
                                <Button
                                  fontSize="14px"
                                  variant="dark"
                                  fontWeight="bold"
                                  w="40%"
                                  h="45"
                                  leftIcon={
                                    <AiOutlineClear color="#FFFFFF" size={21} />
                                  }
                                  backgroundColor={"#3182CE"}
                                  color={"white"}
                                  onClick={() => {
                                    resetForm();
                                  }}
                                >
                                  RESET
                                </Button>
                                <Button
                                  fontSize="14px"
                                  variant="dark"
                                  fontWeight="bold"
                                  w="60%"
                                  h="45"
                                  disabled={isSubmitting}
                                  onClick={handleSubmit}
                                  leftIcon={<RiSendPlaneFill />}
                                >
                                  ENVIAR SEÑAL
                                </Button>
                              </Flex>
                            </FormControl>
                          </form>

                          <Backdrop
                            open={isSubmitting || isLoading}
                            style={{
                              zIndex: 20,
                              color: "#fff",
                            }}
                          >
                            <CircularProgress
                              color="inherit"
                              style={{ color: "#03CFB3" }}
                            />
                          </Backdrop>
                        </>
                      )}
                    </Formik>
                  </Flex>
                </Tbody>
              </Table>
            </CardBody>
          </Card>
        </Flex>
        {/*  <Flex style={{ justifyContent: "center", alignItems: "center" }}>
          <Card
            overflowX={{ sm: "scroll", xl: "hidden" }}
            style={{
              width: "24rem",
              height: "15rem",
              margin: "2rem",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CardBody>
              <Table variant="simple" color={textColor}>
                <Thead></Thead>
                <Tbody>
                  <Flex
                    style={{
                      width: "15rem",
                      height: "auto",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Flex style={{ marginBottom: "2rem" }}>
                      <FormLabel ms="4px" fontSize="sm" fontWeight="bold">
                        Estado Actual
                      </FormLabel>
                      <Input
                        variant="auth"
                        fontSize="sm"
                        ms="4px"
                        type="text"
                        placeholder="1.00420"
                        size="lg"
                        id="form_price"
                        name="price"
                        textAlign={"center"}
                        value={"Manual"}
                        disabled={true}
                      />
                    </Flex>
                  </Flex>
                </Tbody>
              </Table>
            </CardBody>
          </Card>
        </Flex> */}
      </Flex>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} my={"2rem"} p={"2rem"}>
        <CardHeader p="6px 0px 22px 0px">
          <Flex direction="column">
            <Text fontSize="xl" color={textColor} fontWeight="bold" pb=".5rem">
              Todas las señales
            </Text>
          </Flex>
        </CardHeader>
        <CardBody>
          <Table variant="simple" color={textColor}>
            <Thead>
              <div
                style={{
                  width: "100%",
                  height: "auto",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  onClick={() => setUpdate(true)}
                  leftIcon={<HiOutlineRefresh />}
                >
                  Actualizar
                </Button>
              </div>
              <Tr my=".8rem" pl="0px">
                <Th pl="0px" color="gray.400" borderColor={borderColor}>
                  Par
                </Th>
                <Th color="gray.400" borderColor={borderColor}>
                  Precio
                </Th>
                <Th color="gray.400" borderColor={borderColor}>
                  Take Profit
                </Th>
                <Th color="gray.400" borderColor={borderColor}>
                  Stop Loss
                </Th>
                <Th color="gray.400" borderColor={borderColor}>
                  Hora (GMT-5)
                </Th>
                <Th color="gray.400" borderColor={borderColor}>
                  Fecha
                </Th>
                <Th color="gray.400" borderColor={borderColor}>
                  Tipo de Orden
                </Th>
                <Th color="gray.400" borderColor={borderColor}>
                  Posición
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {signals.length ? (
                signals
                  .sort((a, b) => parseInt(b.timestamp) - parseInt(a.timestamp))
                  .map((signal, i) => {
                    if (signal) {
                      return (
                        <TablesProjectRow
                          id={signal.id}
                          pair={signal.pair}
                          title={signal.title}
                          description={signal.description}
                          timestamp={signal.timestamp}
                          operation={signal.operation}
                          takeProfit={signal.takeProfit}
                          stopLoss={signal.stopLoss}
                          price={signal.price}
                          time12h={new Date(
                            parseInt(signal.timestamp),
                          ).toLocaleTimeString("es-CO", {
                            hour12: false,
                          })}
                          date={new Date(
                            parseInt(signal.timestamp),
                          ).toLocaleDateString("es-CO", {
                            hour12: false,
                          })}
                          position={signal.position}
                          isManual={signal.mode === "MANUAL"}
                          createdAt={signal.createdAt}
                          signal={signal}
                          isLast={i === signals.length - 1}
                          setUpdate={setUpdate}
                          key={i}
                        />
                      );
                    }
                  })
              ) : (
                <> </>
              )}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default Manual;
