// chakra imports
import { Avatar, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { ClockIcon } from "components/Icons/Icons";
import {
  AiFillDelete,
  AiFillEdit,
  AiFillEye,
  AiFillPhone,
  AiFillStar,
} from "react-icons/ai";

import React from "react";

export function ItemContentUsers(props) {
  const navbarIcon = useColorModeValue("gray.500", "gray.200");
  const notificationColor = useColorModeValue("gray.700", "white");
  const spacing = " ";

  if (props.type == "delete") {
    return (
      <Flex onClick={props.onPress} alignItems="center" justifyContent="center">
        <AiFillDelete color={"#F56565"} size={22} />
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Text
            fontSize="14px"
            ml="1"
            color={notificationColor}
            textAlign="center"
            fontWeight={"bold"}
          >
            Eliminar
          </Text>
        </Flex>
      </Flex>
    );
  }
  if (props.type == "see") {
    return (
      <Flex onClick={props.onPress} alignItems="center" justifyContent="center">
        <AiFillEye color={"#030303"} size={22} />
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Text
            fontSize="14px"
            ml="1"
            color={notificationColor}
            textAlign="center"
            fontWeight={"bold"}
          >
            Ver detalles
          </Text>
        </Flex>
      </Flex>
    );
  }
  if (props.type == "edit") {
    return (
      <Flex onClick={props.onPress} alignItems="center" justifyContent="center">
        <AiFillStar color={"#f5d365"} size={22} />
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Text
            fontSize="14px"
            ml="1"
            color={notificationColor}
            textAlign="center"
            fontWeight={"bold"}
          >
            Editar Plan
          </Text>
        </Flex>
      </Flex>
    );
  }
  if (props.type == "freesession") {
    return (
      <Flex onClick={props.onPress} alignItems="center" justifyContent="center">
        <AiFillPhone color={"#000000"} size={22} />
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Text
            fontSize="14px"
            ml="1"
            color={notificationColor}
            textAlign="center"
            fontWeight={"bold"}
          >
            Liberar Sesión
          </Text>
        </Flex>
      </Flex>
    );
  }
}
