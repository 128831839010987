import {
  Avatar,
  Badge,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Td,
  Text,
  Tooltip,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { ItemContentUsers } from "components/Menu/ItemContentUsers";
import { FaEllipsisV } from "react-icons/fa";
import Swal from "sweetalert2";
import { API } from "aws-amplify";
import { updateUser } from "graphql/mutations";
import { useEffect } from "react";

function TablesTableRow({
  ID,
  name,
  email,
  phone_number,
  username,
  dateSigned,
  isCompletedKYC,
  currentPlan,
  expoToken,
  date_register,
  isLast,
  userComplete,
  getAllUsers,
  session,
}) {
  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  const verDetalles = async () => {
    Swal.fire({
      title: "Detalles del Usuario",
      html: `
        <div>
          <strong>Nombre:</strong> ${name}<br>
          <strong>Email:</strong> ${email}<br>
          <strong>Teléfono:</strong> ${phone_number}<br>
          <strong>Nombre de Usuario:</strong> ${username}<br>
          <strong>Fecha de Registro:</strong> ${date_register}<br>
          <strong>Plan:</strong> ${currentPlan ? currentPlan.type : "Inactivo"}<br>
          <strong>Token de Expo:</strong> ${expoToken ? expoToken : "NO TIENE"}<br>
          <strong>KYC Completo:</strong> ${isCompletedKYC ? "SI" : "NO"}<br>
          <strong>Sesión:</strong> ${
            session
              ? `
            <div>
              <strong>Nombre del OS:</strong> ${session.osName}<br>
              <strong>Nombre del Dispositivo:</strong> ${session.deviceName}<br>
              <strong>Modelo del Dispositivo:</strong> ${session.deviceModel}<br>
              <strong>Marca del Dispositivo:</strong> ${session.deviceBrand ? session.deviceBrand : "NO TIENE"}<br>
              <strong>Fecha Activa:</strong> ${session.activeDate}
            </div>
          `
              : "NO TIENE"
          }<br>
          <strong>Ha firmado descargos:</strong> ${userComplete.dateSigned ? "SI" : "NO"}<br>
          <strong>Fecha de firma de descargos:</strong> ${userComplete.dateSigned ? userComplete.dateSigned : "NO TIENE"}
        </div>
      `,
      confirmButtonText: "Cerrar",
    });
  };

  const editarPlan = async () => {
    const { value: plan } = await Swal.fire({
      title: "Editar Plan",
      input: "select",
      inputOptions: {
        plan: {
          PROFESSIONAL: "Profesional",
          PLATINUM: "Platino",
          PREMIUM: "Premium",
          DESACTIVAR: "Desactivar Plan",
        },
      },
      inputPlaceholder: "Seleccione un plan",
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return "Debe seleccionar un plan";
        }
      },
    });

    if (plan) {
      try {
        if (plan === "DESACTIVAR") {
          await API.graphql({
            query: updateUser,
            variables: {
              input: {
                id: ID,
                plan: null,
              },
            },
          });
          Swal.fire("Plan desactivado con éxito", "", "success");
        } else {
          await API.graphql({
            query: updateUser,
            variables: {
              input: {
                id: ID,
                plan: {
                  type: plan,
                  activationDate: new Date().toISOString(),
                },
              },
            },
          });
          Swal.fire("Plan actualizado con éxito", "", "success");
        }
        getAllUsers();
      } catch (error) {
        console.error("Error al actualizar el plan:", error);
        Swal.fire("Error", "Error al actualizar el plan", "error");
      }
    }
  };

  const liberarSesion = async () => {
    const result = await Swal.fire({
      title: "¿Desea liberar la sesión del usuario?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, liberar",
      cancelButtonText: "Cancelar",
    });

    if (result.isConfirmed) {
      try {
        await API.graphql({
          query: updateUser,
          variables: {
            input: {
              id: ID,
              session: null,
            },
          },
        });
        Swal.fire("Sesión liberada con éxito", "", "success");
        getAllUsers();
      } catch (error) {
        console.error("Error al liberar la sesión:", error);
        Swal.fire("Error", "Error al liberar la sesión", "error");
      }
    }
  };

  return (
    <Tr>
      <Td
        borderColor={borderColor}
        borderBottom={isLast ? "none" : null}
        style={{ height: "100%", justifyContent: "center" }}
      >
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {ID}
        </Text>
      </Td>
      <Td
        minWidth={{ sm: "250px" }}
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : null}
      >
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Avatar w="50px" borderRadius="100px" me="18px" />
          <Flex direction="column">
            <Text
              fontSize="md"
              color={titleColor}
              fontWeight="bold"
              minWidth="100%"
            >
              {name}
            </Text>
            <Text fontSize="sm" color="gray.400" fontWeight="normal">
              {email}
            </Text>
          </Flex>
        </Flex>
      </Td>

      <Td
        borderColor={borderColor}
        borderBottom={isLast ? "none" : null}
        style={{ height: "100%", justifyContent: "center" }}
      >
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {date_register}
          </Text>
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {phone_number}
          </Text>
        </Flex>
      </Td>
      <Td
        borderColor={borderColor}
        borderBottom={isLast ? "none" : null}
        style={{ height: "100%", justifyContent: "center" }}
      >
        {session ? (
          <Button
            onClick={() =>
              Swal.fire({
                title: "Detalles de la Sesión",
                html: `
                  <div>
                    <strong>OS:</strong> ${session.osName}<br>
                    <strong>Dispositivo:</strong> ${session.deviceName}<br>
                    <strong>Modelo:</strong> ${session.deviceModel}<br>
                    <strong>Marca:</strong> ${session.deviceBrand}<br>
                    <strong>Fecha de Activación:</strong> ${session.activeDate}<br>
                  </div>
                `,
                confirmButtonText: "Cerrar",
              })
            }
          >
            VER SESIÓN
          </Button>
        ) : (
          <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
            NO TIENE
          </Text>
        )}
      </Td>
      <Td
        borderColor={borderColor}
        borderBottom={isLast ? "none" : null}
        style={{ height: "100%", justifyContent: "center" }}
      >
        <Tooltip
          hasArrow
          label={"Token de Notificación: " + expoToken}
          fontSize="sm"
        >
          <Badge
            bg={expoToken == "NO TIENE" ? "gray.400" : "green.500"}
            color={expoToken == "NO TIENE" ? "white" : "white"}
            fontSize="16px"
            p="3px 10px"
            borderRadius="8px"
          >
            {!expoToken || expoToken == "NO TIENE" ? "Inactivo" : "Activo"}
          </Badge>
        </Tooltip>
      </Td>

      <Td
        borderColor={borderColor}
        borderBottom={isLast ? "none" : null}
        style={{ height: "100%", justifyContent: "center" }}
      >
        <Badge
          bg={
            currentPlan
              ? currentPlan.type === "PROFESSIONAL"
                ? "blue.400"
                : currentPlan.type === "PLATINUM"
                  ? "purple.400"
                  : currentPlan.type === "PREMIUM"
                    ? "orange.400"
                    : "green.400"
              : "gray.400"
          }
          color="white"
          fontSize="16px"
          p="3px 10px"
          borderRadius="8px"
        >
          {currentPlan
            ? currentPlan.type === "PROFESSIONAL"
              ? "Profesional"
              : currentPlan.type === "PLATINUM"
                ? "Platino"
                : currentPlan.type === "PREMIUM"
                  ? "Premium"
                  : "Desconocido"
            : "Inactivo"}
        </Badge>
      </Td>

      <Td
        borderColor={borderColor}
        borderBottom={isLast ? "none" : null}
        style={{ height: "100%", justifyContent: "center" }}
      >
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {currentPlan?.activationDate
            ? new Date(currentPlan.activationDate).toLocaleString("co-CO")
            : "Inactivo"}
        </Text>
      </Td>

      <Td
        borderColor={borderColor}
        borderBottom={isLast ? "none" : null}
        style={{ height: "100%", justifyContent: "center" }}
      >
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {isCompletedKYC ? "SI" : "NO"}
        </Text>
      </Td>

      <Td
        borderColor={borderColor}
        borderBottom={isLast ? "none" : null}
        style={{ height: "100%", justifyContent: "center" }}
      >
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {dateSigned ? dateSigned : "NO"}
        </Text>
      </Td>

      <Td borderBottom={isLast ? "none" : null} borderColor={borderColor}>
        <Menu>
          <MenuButton marginLeft={"1rem"}>
            <Icon as={FaEllipsisV} color="gray.400" cursor="pointer" />
          </MenuButton>
          <MenuList p="16px 8px">
            <Flex flexDirection="column">
              <MenuItem borderRadius="8px" mb="10px">
                <ItemContentUsers type="see" onPress={verDetalles} />
              </MenuItem>
              <MenuItem borderRadius="8px" mb="10px">
                <ItemContentUsers type="edit" onPress={editarPlan} />
              </MenuItem>
              <MenuItem borderRadius="8px" mb="10px" onClick={liberarSesion}>
                <ItemContentUsers type="freesession" onPress={liberarSesion} />
              </MenuItem>
              {/*   <MenuItem borderRadius="8px" mb="10px" onClick={liberarSesion}>
                <ItemContentUsers type="delete" onPress={liberarSesion} />
              </MenuItem> */}
            </Flex>
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  );
}

export default TablesTableRow;
